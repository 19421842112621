<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="update_customer" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="تعديل عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{lang.update_customer}}</span>
                </div>
                <div @click="hide" id="hidaddcus" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom backBlack" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cInd" :class="`dispFlex btn btn-small redColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="_indiv"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="_comps"  class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="_goves" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.customerid}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.customerid"
                        readonly
                        />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.mobile"
                        type="number"
                        readonly
                        />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.additional_mobile}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.mobile1"
                        type="number"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <label>{{lang.customer_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{lang.company_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.company_name"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{lang.company_vatid}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.company_vatid"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.building_no}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.building_no"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{lang.street_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.street_name"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.branch_number}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.branch_number"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{lang.district}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.district"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.zipcode}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.zipcode"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{lang.city}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.city"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{lang.address}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.address"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.entity_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.entity_name"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.branch_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.branch_name"
                        />
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
                <!-- <small :style="`color:red;margin-`+lang.lalgin+`:10px`">{{lang.update_all_notes}}</small> -->
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer(0);" variant="success" class="ma-2" style="width:100px;">{{lang.update}}</b-button>
                <!-- <b-button type="button" @click="addCustomer(1);" variant="success" class="ma-2" style="width:100px;">{{lang.update_all}}</b-button> -->
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data: () => ({
        customers:{
            id: '',
            mobile: '',
            mobile1: '',
            full_name: '',
            company_name: '',
            company_vatid: '',
            building_no: '',
            street_name: '',
            branch_number: '',
            district: '',
            zipcode: '',
            city:'',
            address:'',
            customer_type: 1,
            entity_name: '',
            branch_name: '',
            // crt_number: ''
        },
        showCom: false,
        showGov: false,
    }),
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        mobileRules: function() {
            return [
                v => !!v || this.lang.required_field,
                v => (v && v.length <= 10) || this.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.lang.required_field,
            ]
        }
    }, 
    methods: {
        firstUpdate(){
            // // console.log("customer_type1",this.customers);
            if(parseFloat(this.customers.customer_type) == 1) this.changeCusType(0);
            else if(parseFloat(this.customers.customer_type) == 2) this.changeCusType(1);
            else if(parseFloat(this.customers.customer_type) == 3) this.changeCusType(2);
        },
        changeCusType(id){
            // // console.log("customer_type",this.customers);
            if(id == 0){
                this.customers.customer_type = 1;
                this.showCom = false;
                this.showGov = false;
                document.getElementById('_indiv').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('redColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customers.customer_type = 2;
                this.showCom = true;
                this.showGov = false;
                document.getElementById('_comps').classList.remove('hideme');
                document.getElementById('_indiv').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('redColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customers.customer_type = 3;
                this.showCom = false;
                this.showGov = true;
                document.getElementById('_goves').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_indiv').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('redColor');

            }
            // this.changeCtype();
        },
        addCustomer(id){
            // // // console.log();
            if(!this.$refs.form.validate()){
                return false;
            }
            if(this.customers.full_name == '' || this.customers.full_name.length <= 5){
               const message = this.lang.name_info_required;
               this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "updateCustomer");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.customers.id)
            post.append("data[mobile]",this.customers.mobile)
            post.append("data[mobile1]",this.customers.mobile1)
            post.append("data[full_name]",this.customers.full_name)
            post.append("data[company_name]",this.customers.company_name);
            post.append("data[company_vatid]",this.customers.company_vatid);
            post.append("data[building_no]",this.customers.building_no);
            post.append("data[street_name]",this.customers.street_name);
            post.append("data[branch_number]",this.customers.branch_number);
            post.append("data[district]",this.customers.district);
            post.append("data[zipcode]",this.customers.zipcode);
            post.append("data[city]",this.customers.city);
            post.append("data[address]",this.customers.address);
            post.append("data[entity_name]",this.customers.entity_name);
            post.append("data[branch_name]",this.customers.branch_name);
            post.append("data[customer_type]",this.customers.customer_type);
            post.append("updatetype",id);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.$snotify.success(this.lang.customer_addedd, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                    
                    document.getElementById('hidaddcus').click();
                    this.$parent.getClients();
                }else{
                    this.$snotify.error(res.error.customer_updated, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                }
            })
        }
    },
}
</script>